import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CogTitleItems from "../components/CogTitleItems";
import styled from "styled-components";
import DefaultButton from "../components/buttons/DefaultButton";

const StyledCogTitleItems = styled(CogTitleItems)`
  .items {
    @media(max-width: 768px) {
      flex-direction: column;
    }
    &__block {
      padding: 0 2em;
      text-align: center;
      @media(max-width: 768px) {
        margin-bottom: 3em;
      }
    }

    &__title {
      color: #ecc10e;
      padding: 1em 0;
    }

    &__description {
      max-width: 250px;
    }

    button {
      border: none;
    }
  }
`;

const DipendentePensionatoPrivato = () => (
  <StyledCogTitleItems className="mt-5" simple id="section-target">
    <div className="items">
      <div className="items__block">
        <StaticImage
          src="../assets/images/target-dipendente_statale.jpg"
          className="img-fluid mx-auto"
          alt="Logo prestiti"
          placeholder="blurred"
          layout="constrained"
          width={410}
          height={307}
        />
        <p className="pt-4 text-uppercase fw-bolder items__title">
          Sei un dipendente statale?
        </p>

        <DefaultButton arrow title="Scopri di più" url="/cessione-del-quinto-per-dipendenti-pubblici-e-statali/" bgColor="#f1f1f1" className="mt-2 mb-5 mt-lg-5 btn-outline-dark" />
      </div>

      <div className="items__block">
        <StaticImage
          src="../assets/images/target-pensionato.png"
          className="img-fluid mx-auto"
          alt="Logo prestiti"
          placeholder="blurred"
          layout="constrained"
          width={410}
          height={307}
        />
        <p className="pt-4 text-uppercase fw-bolder items__title">
        Sei un pensionato?
        </p>
        <DefaultButton arrow title="Scopri di più" url="/prestiti-personali-per-pensionati-inps/" bgColor="#f1f1f1" className="mt-2 mb-5 mt-lg-5 btn-outline-dark" />
      </div>

      <div className="items__block">
        <StaticImage
          src="../assets/images/target-privato.png"
          className="img-fluid mx-auto"
          alt="Logo prestiti"
          placeholder="blurred"
          layout="constrained"
          width={410}
          height={307}
        />
        <p className="pt-4 text-uppercase fw-bolder items__title">
        Sei un privato?
        </p>
        <DefaultButton arrow title="Scopri di più" url="/finanziamenti/" bgColor="#f1f1f1" className="mt-2 mb-5 mt-lg-5 btn-outline-dark" />
      </div>
    </div>
  </StyledCogTitleItems>
);

export default DipendentePensionatoPrivato;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CogTitleItems from "../components/CogTitleItems";
import styled from "styled-components";
import DefaultButton from "../components/buttons/DefaultButton";

const StyledCogTitleItems = styled(CogTitleItems)`
  .items {
    &__block {
      padding: 0 2em;
    }
  
    &__title {
      color: #F9B233;
      padding: 1em 0;
    }

    button {
      border: none;
    }
  }
`;

const CessioneFinanziamenti = props => {

  const title = "Cessione del Quinto, Prestiti, <br /> Finanziamenti su misura";

  return (
    <StyledCogTitleItems
      title={props.h1 ? `<h1>${title}</h1>` : `<h2 class='fw-bold display-6'>${title}</h2>`}
      description="Per ogni tua esigenza, il tuo Prestito fai da Te"
      className={`mt-2 ${props.className}`}
      id="section-categorie"
    >
      <div className="items">
        <div className="items__block">
          <StaticImage
            src="../assets/images/cs5-cessione-del-quinto.png"
            className="img-fluid mx-auto"
            alt="Cessione del Quinto"
            placeholder="blurred"
            layout="fixed"
            width={410 / 1.5}
            height={307 / 1.5}
          />
          <p className="pt-4 text-uppercase fw-bolder items__title">
            Cessione del Quinto
          </p>
          <p className="items__description">
            Sogna in grande! Ricevi fino a 75.000€ rimborsabili in piccole rate fino a 120 mesi, senza costi o garanzie. Ti basta la tua pensione o busta paga.
          </p>
          <DefaultButton arrow title="Scopri di più" url="/cessione-del-quinto/" bgColor="#f1f1f1" className="mt-2 mb-5 mt-lg-5 btn-outline-dark" />
        </div>

        <div className="items__block">
          <StaticImage
            src="../assets/images/cs5-prestiti-personali.png"
            className="img-fluid mx-auto"
            alt="Prestiti personali"
            placeholder="blurred"
            layout="fixed"
            width={410 / 1.5}
            height={307 / 1.5}
          />
          <p className="pt-4 text-uppercase fw-bolder items__title">
            Prestiti personali
          </p>
          <p className="items__description">
            Dai una svolta alla tua vita! Ottieni fino a 30.000 € rimborsabili in mini rate fino a 120 mesi, senza costi o garanzie. Ti basta la tua pensione o busta paga.
          </p>
          <DefaultButton arrow title="Scopri di più" url="/prestiti-personali/" bgColor="#f1f1f1" className="mt-2 mb-5 mt-lg-5 btn-outline-dark" />
        </div>

        <div className="items__block">
          <StaticImage
            src="../assets/images/cs5-finanziamenti.png"
            className="img-fluid mx-auto"
            alt="Finanziamenti"
            placeholder="blurred"
            layout="fixed"
            width={410 / 1.5}
            height={307 / 1.5}
            loading="lazy"
          />
          <p className="pt-4 text-uppercase fw-bolder items__title">
            Finanziamenti
          </p>
          <p className="items__description">
            Realizza i tuoi piccoli grandi desideri. Da piccole somme fino a 75.000 € rimborsabili in micro rate fino a 120 mesi. Zero costi aggiuntivi e nessuna garanzia: basta la tua busta paga o il cedolino della pensione.
          </p>
          <DefaultButton arrow title="Scopri di più" url="/finanziamenti/" bgColor="#f1f1f1" className="mt-2 mb-5 mt-lg-5 btn-outline-dark" />
        </div>

        <div className="items__block">
          <StaticImage
            src="../assets/images/cs5-mutui.png"
            className="img-fluid mx-auto"
            alt="Mutui"
            placeholder="blurred"
            layout="fixed"
            width={410 / 1.5}
            height={307 / 1.5}
          />
          <p className="pt-4 text-uppercase fw-bolder items__title">Mutui</p>
          <p className="items__description">
            Tante soluzioni di mutuo casa pensate appositamente per andare incontro alle diverse esigenze di coppie, famiglie e singoli alle prese con la scelta della casa e del finanziamento.
          </p>
          <DefaultButton arrow title="Scopri di più" url="/mutui-casa/" bgColor="#f1f1f1" className="mt-2 mb-5 mt-lg-5 btn-outline-dark" />
        </div>
      </div>
    </StyledCogTitleItems>
  );
}

export default CessioneFinanziamenti;

import React from "react";
import SliderBlock from "../components/SliderBlock";
import { StaticImage } from "gatsby-plugin-image";
import DefaultButton from "../components/buttons/DefaultButton";

const SliderOne = (props) => (
  <SliderBlock title="Scopri le nostre soluzioni di prestito e finanziamento" bgColor="#e8f0f6" className="mt-5" id="section-prodotti">
    <div>
      <StaticImage
        src="../assets/images/sl1-prest-personali-insegnanti.png"
        className="img-fluid mx-auto"
        alt="Prestiti in convenzione MIUR: dipendenti Scuola e Università"
        placeholder="blurred"
        layout="fixed"
        width={336}
        height={252}
      />
      <h3 className="slick_slide__title">
        Prestiti in convenzione MIUR: <br />dipendenti Scuola e Università
      </h3>
      <p className="slick_slide__description">
        Tasso agevolato, formula 100% online, zero costi di gestione e rimborso a rate mensili 
        costanti fino ad un massimo di 10 anni. 
        Richiedi subito il tuo prestito online e senza impegno!
      </p>
      <DefaultButton
        title="Approfondisci"
        url="/prestiti-in-convenzione-miur/"
        className="mt-5 btn-outline-dark"
      />
    </div>
    <div>
      <StaticImage
        src="../assets/images/sl1-cess-quinto-NoiPA.png"
        className="img-fluid mx-auto"
        alt="Prestiti in Convenzione NOIPA per Dipendenti PA"
        placeholder="blurred"
        layout="fixed"
        width={336}
        height={252}
      />
      <h3 className="slick_slide__title">Prestiti in Convenzione NOIPA <br /> per Dipendenti PA</h3>
      <p className="slick_slide__description">
        Grazie alla convenzione NoiPA condizioni agevolate per dipendenti 
        pubblici o para-pubblici: fino a 75.000€ con tasso fisso, 
        rimborso fino a 10 anni e rata mai oltre il quinto dello stipendio.
      </p>
      <DefaultButton
        title="Approfondisci"
        url="/prestiti-in-convenzione-noipa/"
        className="mt-5 btn-outline-dark"
      />
    </div>
    <div>
      <StaticImage
        src="../assets/images/sl-1-cess-quinto-pensionati.png"
        className="img-fluid mx-auto"
        alt="Prestiti in convenzione INPS per pensionati"
        placeholder="blurred"
        layout="fixed"
        width={336}
        height={252}
      />
      <h3 className="slick_slide__title">
        Prestiti in convenzione INPS per pensionati
      </h3>
      <p className="slick_slide__description">
        Prestiti in convenzione con Cessione del Quinto a tutti i titolari di pensione INPS e INPS ex INPDAP. 
        100% online, facile, conveniente e soprattutto garantita e sicura. 
        Richiedi il prestito online senza impegno!
      </p>
      <DefaultButton
        title="Approfondisci"
        url="/prestiti-in-convenzione-inps/"
        className="mt-5 btn-outline-dark"
      />
    </div>
    <div>
      <StaticImage
        src="../assets/images/prestiti_personali_carabinieri.png"
        className="img-fluid mx-auto"
        alt="Prestiti agevolati per dipendenti Forze Armate"
        placeholder="blurred"
        layout="fixed"
        width={336}
        height={252}
      />
      <h3 className="slick_slide__title">
        Prestiti agevolati per dipendenti Forze Armate
      </h3>
      <p className="slick_slide__description">
        Prestiti in convenzione per dipendenti delle forze armate che offrono solo vantaggi. 
        Scegli importo e durata del finanziamento. 
        Richiedi la verifica della fattibilità: ti risponderemo entro 1 ora.
      </p>
      <DefaultButton
        title="Approfondisci"
        url="/prestiti-agevolati-per-forze-armate/"
        className="mt-5 btn-outline-dark"
      />
    </div>
  </SliderBlock>
);
export default SliderOne;

import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import Slider from "react-slick";

const StyledSliderBlock = styled.div`
  background-color: ${(props) => props.bgColor};
  .btn {
    display: block;
    margin: auto;
  }

  .slider {
    margin: auto;
  }

  .slick_slide {
    &__title {
      text-align: center;
      margin: 1em 0;
      min-height: 60px;
    }
    &__description {
      padding: 0 40px;
      min-height: 150px;
    }
  }

  .slick-arrow {
    position: absolute;
    background: transparent;
  }
  
  .slick-prev::before, .slick-next::before {
    color: transparent;
  }
`;

const NextArrow = (props) => (
  <StaticImage
    src="../assets/images/icons/slider-next.svg"
    className={props.className}
    alt="Prev slide"
    placeholder="blurred"
    layout="fixed"
    width={18}
    height={30}
    onClick={props.onClick}
    objectFit="contain"
  />
);

const PrevArrow = (props) => (
  <StaticImage
    src="../assets/images/icons/slider-prev.svg"
    className={props.className}
    alt="Prev slide"
    placeholder="blurred"
    layout="fixed"
    width={18}
    height={30}
    onClick={props.onClick}
    objectFit="contain"
  />
);

const defaultSettings = {
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        dots: true,
        arrows: false
      },
    },
  ],
};

const SliderBlock = (props) => (
  <StyledSliderBlock
    className={`pt-2 pb-5 ${props.className}`}
    bgColor={props.bgColor}
  >
    <h2 className="title text-center my-5" dangerouslySetInnerHTML={{ __html: props.title }}></h2>
    <Slider {...(props.settings ? props.settings : defaultSettings)} className="slider container">
      {props.children}
    </Slider>
  </StyledSliderBlock>
);

export default SliderBlock;
